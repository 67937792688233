import image from "./../../images/aprender2.png";

import videos from "./../../images/ft_videos.png";
import lives from "./../../images/ft_lives.png";
import podcast from "./../../images/ft_podcast.png";
import artigos from "./../../images/ft_artigos.png";
import papoglobal from "./../../images/papo-global.jpeg";
import libertaCast from "./../../images/liberta-cast.jpeg";
import cursos from "./../../images/cursos.jpg";
import Modal from "../modal";
import { useState } from "react";

function ContentSection() {
  const [item, setItem] = useState(1);
  const [open, setOpen] = useState(false);

  const handleClick = (number) => {
    console.log(number);
    setItem(number);
    setOpen(true);
  };

  const scrollTo = () => {
    setOpen(false);
    const element = document.getElementById("ancor");
    element.scrollIntoView({ behavior: "smooth" });

    console.log("red", element);
  };

  return (
    <>
      <Modal show={open} onClose={setOpen}>
        <div className="item">
          {item === 1 ? (
            <>
              <span className="play">
                <i className="fas fa-play"></i>
              </span>
              <img src={videos} alt="Vídeos gravados" />
            </>
          ) : item === 2 ? (
            <>
              <span className="live">LIVE</span>
              <img src={lives} alt="Papo global" />
            </>
          ) : item === 3 ? (
            <>
              <img src={artigos} alt="Artigos e colunas" />
            </>
          ) : (
            <>
              <span className="mic">
                <i className="fas fa-microphone-alt"></i>
              </span>
              <img src={podcast} alt="Podcasts" />
            </>
          )}
        </div>

        <div className="text">
          {item === 1 ? (
            <>
              <img src={cursos} alt="Papo global" />{" "}
              <p>
                A Liberta Global está trazendo novos cursos com o melhor do
                conhecimento sobre mercados globais. <br />
                Nossa equipe está trabalhando dia e noite para te trazer
                informações de alta qualidade todos os dias.
              </p>
              <br />
              <div style={{ textAlign: "right" }}>
                <button className="btn-link" onClick={scrollTo}>
                  Fazer minha inscriçao
                </button>
              </div>
            </>
          ) : item === 2 ? (
            <>
              <img src={papoglobal} alt="Papo global" />
              <p>
                Já imaginou um encontro* toda quinta feira com a Liberta Global
                para estar batendo um papo conosco sobre o que está movimentando
                os mercados naquela semana/mês e entender da melhor forma num
                formato descontraído e simples sobre o que está acontecendo?
                Agora você vai! Junte se a nos toda quinta-feira quando Eraldo,
                Leandro e convidados estarão com você no Papo Global.
                <br className="no-mobile" />
                <small style={{ color: "#999" }}>
                  <strong>*</strong> pode ser acessado pelo site ou Youtube.
                </small>
                <br className="no-mobile" />
              </p>
              <br />
              {/* <div style={{ textAlign: "right" }}>
                <a
                  href="https://www.youtube.com/channel/UC5eczp609_uNg-7QAcnlmgA"
                  className="btn-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Conheça nosso canal do Youtube
                </a>
              </div> */}
            </>
          ) : item === 3 ? (
            <>
              <h2>Artigos e colunas</h2>
              <p>
                Semanalmente estaremos trazendo para vocês na nossa Plataforma
                da Liberta Global artigos e noticias trazidas pelos
                profissionais da Liberta Global e parceiros. Procurando estar
                sempre fornecendo a leitura que você precisa para dominar os
                mercados.
                <br className="no-mobile" />
              </p>
              <br />
              <div style={{ textAlign: "right" }}>
                <button className="btn-link" onClick={scrollTo}>
                  Fazer minha inscriçao
                </button>
              </div>
            </>
          ) : (
            <>
              <br className="only-mobile" />
              <img src={libertaCast} alt="LibertaCast" />
              <br />
              <br className="only-mobile" />
              <p>
                Sabemos que estamos sempre em movimento porém a informação nunca
                para! Seja indo para o trabalho ou chegando em casa você poderá
                acessar em breve o podcast da Liberta Global que contará com
                convidados em meio a uma conversa descontraida sobre o que está
                movimentando os mercados. Te deixando ainda mais preparado
                contra qualquer surpresa.
              </p>
              <br />
              <div style={{ textAlign: "right" }}>
                <button className="btn-link" onClick={scrollTo}>
                  Fazer minha inscriçao
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
      <section id="contentSection">
        <div className="container">
          <h2>Em breve uma coleção com o melhor conteúdo</h2>
          <h3>
            Quem faz parte, tem acesso a vídeos, artigos e colunas, podcasts e
            transmissões ao vivo.
          </h3>

          <div className="blocks">
            <div className="item" onClick={() => handleClick(1)}>
              <span className="play">
                <i className="fas fa-play"></i>
              </span>
              <img src={videos} alt="Cursos" />
              <p>Cursos</p>
            </div>

            <div className="item" onClick={() => handleClick(2)}>
              <span className="live">LIVE</span>
              <img src={lives} alt="Papo global" />
              <p>Papo Global</p>
            </div>

            <div className="item" onClick={() => handleClick(3)}>
              <img src={artigos} alt="Artigos e colunas" />
              <p>Artigos e colunas</p>
            </div>

            <div className="item" onClick={() => handleClick(4)}>
              <span className="mic">
                <i className="fas fa-microphone-alt"></i>
              </span>
              <img src={podcast} alt="Podcasts" />
              <p>Podcasts</p>
            </div>
          </div>

          <div className="bestway">
            <article className="list-article">
              <h2>O que queremos te ensinar</h2>

              <ul>
                <li>Analise técnica e fundamentalista</li>
                <li>Como achar as melhores ações e ETFs</li>
                <li>Macroeconomia e microeconomia</li>
                <li>Psicologia e neurociência do investidor</li>
                <li>Filosofia para alcançar liberdade financeira</li>
                <li>Investimentos alternativos como criptomoedas</li>
                <li>Preservação e crescimento de capital</li>
                <li>Dolarização de patrimônio e renda em dólar</li>
              </ul>

              {/* <a
                href="https://www.youtube.com/channel/UC5eczp609_uNg-7QAcnlmgA"
                className="youtube-link"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-youtube"></i>
                <span>Conheça nosso Canal do Youtube</span>
              </a> */}
            </article>

            <img src={image} alt="O melhor jeito de aprender" />
          </div>
        </div>
      </section>
      <div id="ancor" style={{ height: 100 }}></div>
    </>
  );
}

export default ContentSection;
