function FooterSection() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="rights">
          <p>
            <strong>Liberta Global</strong> &copy; 2021. Todos os direitos
            reservados.
          </p>
          {/* <ul>
            <li>
              <a href="/#">Políticas</a>
            </li>
            <li>
              <a href="/#">Termos e Condições</a>
            </li>
            <li>
              <a href="/#">Proteção de dados pessoais</a>
            </li>
          </ul> */}
        </div>
        <div className="disclaimer">
          <h3>DISCLAIMER</h3>
          <p>
            As informações aqui apresentadas são genéricas e não devem ser
            consideradas como uma recomendação individualizada ou conselho de
            investimento personalizado . As estratégias de investimento
            mencionadas aqui podem não ser adequadas para todos. Cada investidor
            precisa avaliar uma estratégia de investimento levando em conta a
            sua própria situação particular e o seu perfil antes de tomar
            qualquer decisão de investimento. Símbolos de ativos e derivativos
            são apresentados apenas para fins ilustrativos e não devem ser
            interpretados como uma recomendação ou uma oferta de compra ou
            venda, ou uma solicitação de uma oferta para comprar quaisquer
            títulos. Todas as opiniões expressas estão sujeitas a alteração sem
            aviso prévio em reação à mudança de condições de mercado.
            Eventualmente são apresentados aqui dados de terceiros considerados
            fontes confiáveis. No entanto, a precisão, integridade ou
            confiabilidade desses dados não pode ser garantida. Nós não
            recomendamos o uso de análise técnica como um único meio de pesquisa
            de investimento. Operar ativos e derivativos pode envolver alto
            risco e a perda de todo o capital investido. A informação sobre
            investimentos fornecida pode não ser apropriada para todos os
            investidores, ela é fornecida sem respeito a sofisticação financeira
            do investidor individual, situação financeira horizonte de tempo de
            objetivo ou tolerância ao risco. As apresentações aqui divulgadas
            são apenas para fins educacionais e não são uma recomendação ou
            endosso de qualquer estratégia de investimento em particular. O
            desempenho passado não indica nem garante o sucesso futuro. O
            retorno irá variar e todos os investimentos envolvem riscos,
            incluindo a perda do principal. Polegares para cima/baixo ou outras
            avaliações de leitores são submetidos de forma voluntária e não se
            destinam a sugerir o futuro desempenho ou adequação de qualquer tipo
            de conta, produto ou serviço para qualquer leitor específico e podem
            não ser representativos da experiência de outros leitores. Quando
            exibido, polegares para cima ou para baixo ou ainda outros
            indicadores visuais de um conteúdo representam se as pessoas
            avaliaram o conteúdo útil ou não útil, e não devem ser considerados
            um depoimento. Qualquer comentário escrito ou comentários recolhidos
            na presente página não serão publicados. Podemos a nosso exclusivo
            critério zerar o contador de votos, retirar votos que parecem ser
            gerados por robôs ou scripts, ou remover os módulos usados para
            coletar feedback e votos. Nem a Liberta Global nem qualquer de seus
            diretores, funcionários, representantes, agentes ou contratados
            independentes são, em tais capacidades, consultores financeiros
            licenciados, consultores de investimento registradas ou corretores
            registrados. A Liberta Global não fornece investimentos ou
            aconselhamento financeiro ou faz recomendações de investimento, nem
            é um negócio que intermedeia operações de mercado, nem oferece
            contas para operações com commodities ou dá recomendações sobre
            commodities levando em considerações características e necessidades
            pessoais. Nada contido neste espaço constitui uma solicitação, a
            recomendação, promoção, endosso ou oferta da Liberta Global de
            qualquer título particular, transação ou investimento. Por favor,
            lembre-se que as comunicações neste site são públicas, por isso,
            proteja sua própria privacidade e trate outros participantes com
            respeito. Por favor saiba que nós monitoramos comunicação neste site
            e proibimos qualquer solicitação, oferta de produto ou serviço ou
            comentários inadequados. As opções não são adequadas para todos os
            investidores. Os riscos especiais inerentes à negociação de opções
            pode expor os investidores a perdas potencialmente rápidas e
            substanciais. Antes de negociar opções, você deve ler cuidadosamente
            a cópia fornecida anteriormente do documento "Características e
            Riscos de Opções Padronizado". Spreads, Straddles e outras
            estratégias com opções de múltiplas pernas podem implicar custos de
            transação significativos, incluindo várias comissões, que podem
            afetar qualquer retorno potencial. Estratégias avançadas de opções
            envolvem muitas vezes maior risco e maior complexidade do que o
            risco oferecido pelas operações com uma única perna. Os investidores
            também devem considerar contatar um consultor fiscal sobre o
            tratamento tributário aplicável aos spreads e outras transações de
            opções com várias pernas. A negociação de futuros e forex envolve
            especulação, e o risco de perda pode ser substancial. Backtesting é
            a avaliação de uma estratégia de negociação particular, usando dados
            históricos. Os resultados apresentados são hipotéticos, ou seja, as
            operações não foram feitas necessariamente e podem não levar em
            consideração todos os custos de transação ou impostos em comparação
            a uma transação real. Nenhuma parte deste site pode ser copiada,
            gravada ou retransmitida de qualquer forma sem o consentimento
            prévio por escrito. Compartilhando Informações com Afiliados:
            Respeitamos o direito de cada indivíduo à privacidade. Entendemos a
            importância de colocar a privacidade e a segurança das informações
            que o identifiquem pessoalmente ou informações de sua conta.
            Limitamos a coleta e o uso de informações pessoais para o que é
            necessário para administrar o nosso negócio e para fornecer serviços
            para você. Podemos compartilhar informações pessoais com um agente
            ou empresas afiliadas. Por exemplo, podemos compartilhar suas
            informações com prestadores de serviços sob contrato que ajudem com
            as operações de negócios, tais como: projeto de coleta, prevenção da
            fraude, marketing e serviços de tecnologia. Quando compartilhamos
            informações pessoais com um agente ou afiliadas, protegemos as
            informações pessoais com um acordo de confidencialidade. As empresas
            que contratamos para prestar serviços de apoio ou atuar como nosso
            agente devem estar de acordo com nossos padrões de privacidade. Nós
            não vendemos as informações pessoais a ninguém. Abrimos informações
            pessoais em circunstâncias limitadas onde acreditamos de boa fé que
            a divulgação é exigida por lei, cooperamos com os reguladores ou
            autoridades responsáveis pela aplicação da lei, ou para proteger os
            nossos direitos ou propriedade.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default FooterSection;
