import { useState } from "react";

function MailChimpForm({ status, message, onValidated }) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [invest, setInvest] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      firstName &&
      lastName &&
      email.indexOf("@") > -1 &&
      onValidated({
        MERGE0: email,
        MERGE1: firstName,
        MERGE2: lastName,
        MERGE3: invest,
      });
  };

  return (
    <>
      <div className="form">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="row">
            <div className="form-group space-right">
              <label htmlFor="nome">Nome</label>
              <input
                type="text"
                name="nome"
                id="nome"
                on
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                required
              />
            </div>
            <div className="form-group space-left">
              <label htmlFor="nome">Sobrenome</label>
              <input
                type="text"
                name="sobrenome"
                id="sobrenome"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="nome">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </div>

          <h3>Investir é uma prioridade para você?</h3>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="radio"
                onChange={(e) => setInvest(e.target.value)}
                value="Sim"
                checked={invest === "Sim"}
                required
              />
              <span>Sim</span>
            </label>

            <label>
              <input
                type="radio"
                name="radio"
                onChange={(e) => setInvest(e.target.value)}
                value="Não"
                checked={invest === "Não"}
                required
              />
              <span>Não</span>
            </label>
          </div>
          <button
            type="submit"
            className={status === "sending" ? "btn-cta disabled" : "btn-cta"}
            formvalues={[email, firstName, lastName, invest]}
            disabled={status === "sending"}
            readOnly={status === "sending"}
          >
            {status !== "sending" ? "Enviar" : "Enviando..."}
          </button>

          {status === "error" && (
            <span
              className="alert alert-error"
              dangerouslySetInnerHTML={{
                __html: "Ops... Usuário já inscrito ou dados incorretos.",
              }}
            />
          )}
          {status === "success" && (
            <span
              className="alert alert-success"
              dangerouslySetInnerHTML={{
                __html: "Inscrição realizada com sucesso!",
              }}
            />
          )}
        </form>
      </div>
    </>
  );
}

export default MailChimpForm;
