import MailchimpSubscribe from "react-mailchimp-subscribe";

import MailChimpForm from "../MailchimpForm";

function ContactSection() {
  const postUrl = `https://gmail.us6.list-manage.com/subscribe/post?u=5af2f7bb21e40551cdcf47f57&amp;id=050b3c8fe9`;

  return (
    <section id="contactSection">
      <div className="container">
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M50.0001 120C48.3351 119.721 46.6526 119.361 45.0001 118.933V57.9006H25.0001V109.508C22.3593 107.591 19.8763 105.462 17.5752 103.143C12.0951 97.6407 7.72754 91.1192 4.71509 83.9407C1.58716 76.4993 -0.0165146 68.4985 0.000128221 60.418C-0.0162533 52.3391 1.58742 44.34 4.71509 36.9003C7.72759 29.7218 12.0951 23.2003 17.5752 17.6975C23.0395 12.1793 29.5159 7.78216 36.645 4.75044C44.0336 1.60152 51.9771 -0.0141442 60.0001 0.000104164C68.023 -0.0149406 75.9665 1.59988 83.3552 4.74795C90.4839 7.78057 96.9601 12.1775 102.425 17.695C107.905 23.1974 112.272 29.7191 115.283 36.8978C118.41 44.3386 120.015 52.3382 120 60.418C120 70.3862 117.555 80.2001 112.883 88.9881C108.337 97.4742 101.856 104.753 93.9729 110.225L95.0001 25.1742H75.0001L75.2199 118.872C73.5098 119.323 71.7551 119.7 70.0001 119.995V40.2787H50.0001V120Z"
            fill="white"
          />
        </svg>

        <div className="contact-form">
          <div>
            <h2>Venha fazer parte</h2>
            <p style={{ lineHeight: 1.6, marginRight: "3rem" }}>
              Fique por dentro de todos updates, promoções e avisos sobre a
              nossa empresa <br />e continue ligado sobre tudo
              <strong> o que está acontecendo no mercado global</strong> com a
              gente.
            </p>
            <br />
            {/* <img src={imgMercado} alt="Mercado americano simplificado" /> */}
          </div>
          <MailchimpSubscribe
            url={postUrl}
            render={({ subscribe, status, message }) => (
              <MailChimpForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
        </div>
        <br />
        <br />

        <div className="social-area">
          <h2>A Liberta Global nas redes sociais</h2>

          <a
            href="https://www.instagram.com/libertaglobal/"
            target="_blank"
            className="social"
            rel="noreferrer"
          >
            <i className="fab fa-instagram"></i> <span>@libertaglobal</span>
          </a>
          {/* <a
            href="https://www.youtube.com/c/LibertaGlobal10/"
            target="_blank"
            className="social"
            rel="noreferrer"
          >
            <i className="fab fa-youtube-square"></i>
            <span>Canal do Youtube</span>
          </a> */}
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
