function AboutSection() {
  return (
    <section id="aboutSection">
      <div className="container">
        <article>
          <h2>
            Sobre a <strong>Liberta Global</strong>
          </h2>
          <h3>Quem somos e o que fazemos</h3>
          <p>
            Com escritório em Miami, a <strong>Liberta Global</strong> foca no
            mercado norte-americano. Desenvolve cursos e conteúdos em português
            para quem deseja ampliar seus horizontes e{" "}
            <strong>investir nos Estados Unidos.</strong>
          </p>
          <br />
          <p>
            Acreditamos em novos e inovadores meios de educar e investir. A
            pluralidade de possibilidades no mercado, liquidez, seriedade,
            evolução, enfim, inúmeros atributos que o sistema financeiro
            norte-americano oferece, faz com que estejamos sempre atualizados e
            focados no que é importante.
          </p>
          <br />
          <p>
            Desta forma, criamos linhas de aprendizado que facilitam o
            entendimento e prática dos investidores em um mercado tão
            diferenciado e promissor.
          </p>

          <p className="credits">
            <i className="fas fa-map-marker-alt"></i>
            <span>
              <strong>Miami</strong> | Florida - USA
            </span>
          </p>
        </article>
        <div className="img-about"></div>
      </div>
    </section>
  );
}

export default AboutSection;
