import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

function BannerSection() {
  const scrollTo = () => {
    const element = document.getElementById("ancor");
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section id="bannerSection">
      <div className="container relative">
        <Carousel
          showStatus={false}
          autoPlay
          interval="12000"
          infiniteLoop
          transitionTime="1000"
        >
          {/* <div className="img-banner">
            <article>
              <h1>Em breve uma nova plataforma da Liberta Global</h1>
              <p>
                A Liberta Global com a missão de continuar educando o público
                sobre educação financeira e investimento em mercados globais
                está construindo uma nova plataforma que irá reunir o melhor
                conteúdo em cursos, serviços, lives e todo o necessário para
                você ter sucesso nos seus investimentos. Esta é uma das nossas
                iniciativas mais audaciosas e temos certeza que cumpriremos da
                melhor forma.
              </p>

              <button onClick={scrollTo} className="btn-cta">
                Fazer minha inscrição
              </button>

              <a
                href="https://www.instagram.com/libertaglobal/"
                target="_blank"
                className="social"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i> <span>@libertaglobal</span>
              </a>
              <a
                href="https://www.youtube.com/c/LibertaGlobal10/"
                target="_blank"
                className="social"
                rel="noreferrer"
              >
                <i className="fab fa-youtube-square"></i>
                <span>Canal do Youtube</span>
              </a>
            </article>
          </div> */}

          <div className="img-banner slide2">
            <article>
              <h1>Nós somos a Liberta Global</h1>
              <p>
                A Liberta Global é uma empresa de educação financeira voltada
                para o ensino de mercados globais. Baseada em Miami, temos a
                missão de ensinar cada vez mais o público brasileiro a investir
                fora do país com expertise e segurança. Estamos aqui para você.
              </p>

              <button onClick={scrollTo} className="btn-cta">
                Fazer minha inscrição
              </button>
            </article>
          </div>

          <div className="img-banner slide3">
            <article>
              <h1>A Liberta Global apoia o esporte</h1>
              <p>
                A Liberta Global embarcou na missão de educar cada vez mais os
                atletas do nosso Brasil. Em 2020 trouxemos o curso "O jogo além
                da quadra" em parceria com Confederação Brasileira de Basquete a
                que disponibilizou contéudo gratuito para todos os atletas do
                Brasil vinculados com a CBB sobre educação financeira. Para
                continuar nossa parceria com o esporte nós tornamos
                patrocinadores também da seleção Brasileira de Basquete no
                pré-olímpico
              </p>

              <button onClick={scrollTo} className="btn-cta">
                Fazer minha inscrição
              </button>
            </article>
          </div>

          <div className="img-banner slide4">
            <article>
              <h1>Na Liberta Global você está conectado a todos os mercados</h1>
              <p>
                A Liberta Global com a missão de continuar educando o público
                sobre educação financeira e investimento em mercados globais
                está construindo uma nova plataforma que irá reunir o melhor
                conteúdo em cursos, serviços, lives e todo o necessário para
                você ter sucesso nos seus investimentos. Esta é uma das nossas
                iniciativas mais audaciosas e temos certezas que cumpriremos da
                melhor forma.
              </p>

              <button onClick={scrollTo} className="btn-cta">
                Quero Ser Aluno
              </button>
            </article>
          </div>
        </Carousel>

        <div className="bars">
          <button className="bar"></button>
          <button className="bar"></button>
          <button className="bar"></button>
        </div>
      </div>
    </section>
  );
}

export default BannerSection;
