import Navbar from "./components/navbar";
import AboutSection from "./components/sections/about";
import BannerSection from "./components/sections/banner";
import ContactSection from "./components/sections/contact";
import ContentSection from "./components/sections/content";
import FocusSection from "./components/sections/focus";
import FooterSection from "./components/sections/footer";
import NumberSection from "./components/sections/numbers";

function App() {
  return (
    <div id="wrapper">
      <Navbar />
      <BannerSection />
      <NumberSection />
      <AboutSection />
      <FocusSection />
      <ContentSection />
      <ContactSection />
      <FooterSection />
    </div>
  );
}

export default App;
