import logo from "./../images/logo-liberta-global.png";

function Navbar() {
  return (
    <header id="navbar">
      <div className="container">
        <img src={logo} alt="" />
        <a
          href="http://site.libertaglobal.com/account/sign_in"
          className="btn-link"
        >
          Já sou aluno
        </a>
      </div>
    </header>
  );
}

export default Navbar;
