import { useEffect, useState } from "react";

function Modal({ show, children, onClose }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose(false);
  };

  return (
    <div className={open ? "modal-screen open" : "modal-screen"}>
      <div className="modal">
        <div className="modal-body">
          <button className="close" onClick={handleClose}>
            <i className="fas fa-times"></i>
          </button>
          <div className="row">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
