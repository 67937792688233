import image from "./../../images/icons-cloud.png";

function FocusSection() {
  return (
    <section id="focusSection">
      <div className="container">
        <img src={image} alt="Nosso foco em 2021" />

        <article className="list-article">
          <h2>Nosso foco em 2021</h2>

          <ul>
            <li>
              <strong>Educação financeira e investimentos </strong>em mercados
              globais
            </li>
            <li>
              <strong>Pioneiros</strong> em educação financeira para atletas
              profissionais
            </li>
            <li>
              <strong>Papo Global</strong> - Nosso programa no Youtube toda
              semana
            </li>
            <li>
              <strong>LibertaCast</strong>, o podcast com os maiores
              especialistas do mercado global
            </li>
            <li>
              Serviços de <strong>assinatura exclusiva</strong>
            </li>
            <li>
              <strong>Novos cursos</strong> para te atender
            </li>
            <li>
              <strong>Nova linguagem</strong> para o mercado financeiro
            </li>
            <li>
              <strong>Patrocinador pontual</strong> da{" "}
              <strong>Seleção Brasileira de basquete no Pré-Olímpico</strong>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
}

export default FocusSection;
