function NumberSection() {
  return (
    <section id="numberSection">
      <div className="container">
        <div className="widget">
          <p className="number">
            <i className="fas fa-book"></i>
          </p>
          <p className="label">
            <strong>Primeira empresa educacional</strong> focada em mercados
            globais para brasileiros
          </p>
        </div>

        <div className="widget">
          <p className="number">+ de 30 mil</p>
          <p className="label">
            <strong>Alunos </strong>no Brasil <br /> e nos Estados Unidos
          </p>
        </div>

        <div className="widget">
          <p className="number">
            <i className="fas fa-photo-video"></i>
          </p>
          <p className="label">
            Mais de <strong>40 horas</strong> de conteúdo gravados{" "}
            <strong>para você</strong>.
          </p>
        </div>
      </div>
    </section>
  );
}

export default NumberSection;
